.nav-wrap {
    background-color: var(--bg-color) !important;
    position: fixed;
    top: 0;
    z-index: 2;
    width: 100%;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0rem;
    width: 100%;
}

.navbar-brand img {
    transition: all 0.3s ease;
    z-index: 3;
    position: relative;
}

.navbar-brand img:hover {
    transform: scale(1.05);
}

/* Hamburger Icon */
.burger-box {
    display: none;
    cursor: pointer;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 25px;
    z-index: 3;
}

.burger-bar {
    width: 100%;
    height: 3px;
    background-color: #fff;
    border-radius: 10px;
    transition: all 0.3s linear;
}

/* Nav Links */
.nav-links {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    align-items: center;
    justify-content: center;
}

.nav-item {
    margin: 0 .5rem;
}

.nav-link {
    text-decoration: none;
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: 600;
    font-size: 1rem;
    padding: .5rem 1rem;
    border-radius: var(--border-radius);
    transition: all 0.3s ease;
}

.nav-link:hover {
    background: var(--primary);
}

.nav-link-active {
    background: var(--secondary);
    border-radius: var(--border-radius);
}

.user-info {
    display: flex;
    align-items: center;
    gap: .5rem;
    color: #fff;
    font-weight: 600;
    font-size: 1.2rem;
}

.user-info:hover {
    cursor: pointer;
}

.user-info img {
    border-radius: 50%;
    border: .5rem solid #20252e;
}

.name-wrap {
    display: flex;
    flex-direction: column;
}

.name-wrap a {
    color: var(--primary);
    text-decoration: none;
    font-size: .9rem;
    font-weight: 400;
    line-height: .9rem;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
    .burger-box {
        display: flex;
    }

    .nav-links {
        position: absolute;
        right: 0;
        height: 100vh;
        top: 0;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        justify-content: center;
        width: 100%;
        transform: translateX(100%);
    }

    .nav-links.open {
        transform: translateX(0);
    }

    .burger-box.open .burger-bar:nth-child(1) {
        transform: rotate(-45deg) translate(-5px, 6px);
    }

    .burger-box.open .burger-bar:nth-child(2) {
        opacity: 0;
    }

    .burger-box.open .burger-bar:nth-child(3) {
        transform: rotate(45deg) translate(-5px, -6px);
    }

    .open {
        background-color: var(--bg-color) !important;
    }
}