.profile-wrap {
    padding-top: 10rem;
}

.profile {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: flex-start;
    justify-content: center;
}

.votes {
    display: flex;
    flex-direction: column;
    gap: 0rem;
    margin-top: 2rem;
    gap: 2rem;
}

.upvotes {
    border: 2px solid var(--green);
    padding: 1rem;
    border-radius: 50%;
    color: var(--green);
    font-weight: 500;
    font-size: 1.5rem;
    height: 2rem;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.upvotes .status {
    position: absolute;
    top: -1rem;
    padding: 0;
    width: 30px;
    height: 30px;
    min-width: 20px;
}

.downvotes {
    border: 2px solid #e99400;
    padding: 1rem;
    border-radius: 50%;
    color: #e99400;
    font-weight: 500;
    font-size: 1.5rem;
    height: 2rem;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.downvotes .orange {
    background: #e99400 !important;
    position: absolute;
    top: -1rem;
    padding: 0;
    width: 30px;
    height: 30px;
    min-width: 20px;
}

.downvotes .orange img {
    height: 7px;
}

.avatar-wrap {
    border-radius: 50%;
    border: 14px solid #0e0f11;
}

.circle {
    border: 2px solid var(--green);
    border-radius: 50%;
    margin: .5rem;
}

.avatar-wrap img {
    border-radius: 50%;
    border: 25px solid #161616;
}

.profile-info {
    display: flex;
    flex-direction: column;
    min-width: min(100%, 550px);
    gap: 1rem;
}

.profile-info input {
    width: 100%;
    background-color: #0e0f11;
    color: #fff;
    outline: none;
    border: none;
    line-height: 2rem;
    font-size: .9rem;
    padding: 0 1rem;
    font-weight: 600;
}

.profile-info .input-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile-info h1 {
    color: #fff;
    font-weight: 500;
    margin: 0;
}

.deals {
    display: flex;
    gap: .3rem;
    align-items: center;
}

.deals h4 {
    color: #fff;
    margin: 0;
}

.deals p {
    color: #61738a;
    font-weight: 500;
    margin: 0;
}

.linked {
    display: flex;
    gap: .5rem;
    align-items: center;

}

.linked p {
    color: #61738a;
    margin: 0;
    font-weight: 500;
}

.all-deals {
    display: flex;
    gap: 3rem;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}

@media screen and (max-width: 768px) {
    .profile {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .votes {
        flex-direction: row;
        margin-top: 0;
    }

    .all-deals {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}