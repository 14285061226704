.feedback-wrap {
    margin-left: 35px;
    padding: 1rem;
    position: relative;
    min-height: 150px;
    background: #1f2227;
}

.feedback-all-wrap .feedback-wrap:nth-child(2n) {
    background: #15171a !important;
}

.top-name {
    color: #fff;
    opacity: .5;
    font-weight: 700;
    font-size: .9rem;
    margin-left: 4rem;
    margin-block-end: .3rem;
    margin-top: 2rem;
}

.feedback-wrap .avatar {
    position: absolute;
    top: 0;
    left: -35px;
    top: 45%;
    transform: translateY(-50%);
    border-radius: 50%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--green);
}

.feedback-wrap .date {
    /* float: right; */
    text-align: right;
    color: #fff;
    opacity: .2;
    font-size: .9rem;
    font-weight: 500;
    margin-top: auto;
    margin-block-end: 0;
}

.feedback-info {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-left: 75px;
}

.arrow-wrap {
    background: var(--green);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.feedback-text strong {
    color: #fff;
}

.feedback-text {
    color: var(--text-color);
    margin-block-end: .5rem !important;
}

.message {
    color: var(--text-color);
    margin-top: 0;
}

.link-wrap {
    text-align: right;
}

@media screen and (max-width: 768px) {
    .feedback-wrap {
        margin-left: 0;
    }

    .feedback-info {
        margin-left: 0;
    }

    .avatar {
        top: 0 !important;
        left: 0;
        margin-left: 0;
        right: 0;
        margin: 0 auto;
        transform: translateY(0);
    }

    .top-name {
        margin-left: 0;
        margin-top: 5rem;
    }
}