.search-wrap {
    max-width: 600px;
    margin: 0 auto;
}

.search-wrap h2 {
    color: white;
    font-weight: 800;
    text-align: center;
    margin-top: 2rem;
    font-size: 1.4rem;
}

.input-wrap {
    display: flex;
    background: #0e0f11;
    padding: .5rem;
    position: relative;
}

.input-wrap input {
    width: 100%;
    padding: 0.9rem;
    font-size: 1rem;
    color: #fff;
    border-radius: 0 !important;
    background: #171819;
    font-weight: 500 !important;
    outline: none !important;
    border: 1px solid #282f39;
}

.search-btn {
    background: transparent !important;
    outline: none !important;
    border: none !important;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-45%);
    z-index: 1;
    transition: all .3s ease;
}

.search-btn:hover {
    cursor: pointer;
    transform: translateY(-45%) scale(1.1);
}

.enter-wrap {
    display: flex;
    gap: .5rem;
    align-items: center;
    margin-top: 1rem;
    justify-content: center;
}
.enter-text {
    color: #fff;
    font-size: .9rem;
    font-weight: 500;
}