.filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filter h2 {
    color: #fff;
    font-weight: 500;
    font-size: 1.2rem;
}

.filter-options {
    color: #fff;
    display: flex;
    align-items: center;
    gap: .5rem;
}

.filter-options p {
    margin-block-end: 0;
    margin-top: 0;
}

.icon-wrap {
    display: flex;
    align-items: center;
    gap: .5rem;
    border: 2px solid var(--secondary);
    padding: .3rem;
    border-radius: var(--border-radius);
}

.icon-wrap:hover {
    background: var(--secondary);
    cursor: pointer;
}

.icon-active {
    background: var(--secondary);
}

.sort-by {
    display: flex;
    color: #fff;
    gap: .5rem;
    align-items: center;
}

select {
    background: #171819;
    color: #fff;
    height: 45px;
    line-height: 45px;
    padding: 0 35px 0 5px;
    font-size: 16px;
    border: none;
}

@media screen and (max-width: 768px) {
    .filter {
        flex-direction: column;
        gap: 1rem;
    }
}