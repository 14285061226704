.ad {
    width: 100%;
    margin: 1rem 0;
    border-radius: var(--border-radius);
}

.statistics {
    display: flex;
    justify-content: center;
}

.statistics .statistics__wrapper {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 20px;
}

.statistics .statistics__wrapper .stats-box {
    display: flex;
    align-items: center;
    width: 347px;
    height: 98px;
    padding: 0 20px;
    background: #1f2227;
    border-radius: var(--border-radius);
}

.status.green, .status.positive {
    background-color: #30a200;
}

.status.red {
    background-color: #c40000;
}

.status.blue {
    background-color: #0092d6;
}

.status {
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    min-width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: #1c1c1c;
}

.statistics .statistics__wrapper .stats-box .status {
    margin-right: 20px;
}

.statistics .statistics__wrapper .stats-box .stats .number {
    font-size: 24px;
    color: #fff;
    padding-bottom: 5px;
}

.statistics .statistics__wrapper .stats-box .stats .title {
    font-size: 14px;
    color: #6f7680;
}

.upper-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 4rem;
    padding-bottom: 4rem;
    border-bottom: 1px solid var(--secondary)
}

.upper-footer .left {
    color: #fff;
}

.upper-footer .left p {
    font-size: 14px;
    color: #909baa;
    font-weight: 500;
}

.middle {
    min-width: 200px;
    padding: 0rem 4rem;
}

.middle ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.middle a {
    text-decoration: none;
    color: var(--text-color);
    font-weight: 500;
}

.middle a:hover {
    color: var(--primary);
}

.middle h4 {
    font-size: 16px;
    color: #fff;
    margin-block-end: 0;
    font-weight: 500;
    margin-block-start: 0;
}

.lower-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.lower-footer p strong {
    color: var(--secondary);
    margin-right: .6rem;
}

.lower-footer p {
    font-weight: 800;
    color: #fff;
    font-size: .9rem;
}

.twitter {
    display: flex;
    align-items: center;
    color: var(--text-color);
}

.twitter:hover {
    color: var(--primary);
}

.twitter img {
    margin-top: .2rem;
}

@media only screen and (max-width: 722px) {
    .statistics .statistics__wrapper {
        grid-template-columns: 1fr!important;
        grid-row-gap: 20px;
    }

    .statistics .statistics__wrapper .positive_feedbacks, .statistics .statistics__wrapper .users_registered {
        justify-self: center!important;
    }

    .statistics .statistics__wrapper .stats-box {
        width: 304px!important;
    }
}

@media only screen and (max-width: 1080px) {
    .statistics .statistics__wrapper .positive_feedbacks {
        justify-self: end;
    }

    .statistics .statistics__wrapper .stats-box {
        width: 200px;
    }

    .upper-footer {
        flex-direction: column;
        align-items: center;
    }
}